.linkTreeContainer {
    background-color: black;
    height: 100%;
    position: fixed;
    width: 100%;
    height: 100%;

    font-family: AgrandirHeavy;
}
.mainFlex {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1em;

    padding: 2em;
}

.mainIcon {
    max-width: 128px;
    max-height: 128px;
}