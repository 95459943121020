:root {
    --headingHeight: 25em;
    --mainMargin: 2em;
}

#mainGridContainer {
    background-color: #aad7f3;

    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;

    height: 100%;
    font-family: AgrandirHeavy;
}

#mainPage {
    background: url('../images/backgroundblurred.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-width: 700px;

    height: 100%;
    min-height: 100vh;
}

#mainBackground,
#mainContent {
    position: relative;
    width: 100%;
    height: 100vh;
}

#backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    z-index: -1000;
}

#header {
    padding-top: var(--mainMargin);
}

#logoContainer {
    position: relative;
    width: 100%;
    min-height: max(min(20vw, var(--headingHeight)), 300px);
}

#topShape {
    position: absolute;
    height: 100%;
}

#textLogo {
    position: absolute;
    height: 75%;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: calc(var(--mainMargin) - 1em);
}

#middleShape {
    width: 50%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

#bottomShape {
    width: 50%;
    bottom: 0;
    left: 0;
}

#bodyContent {
    margin: 0em var(--mainMargin) var(--mainMargin) var(--mainMargin);
}

.bodyText {
    font-size: 3vh;
}

.shape {
    position: absolute;
}

.scrollContainer {
    height: 100%;
    overflow-y: hidden;
    
    display: flex;
    flex-direction: column;
}

.scrollContainer > * {
    flex: 1;
}

@media only screen and (max-width: 768px) {
    :root {
        --mainMargin: 2em;
    }

    .bodyText {
        font-size: 2em;
    }
}

@media only screen and (max-width: 1024px) {
    #mainPage {
        min-width: 0px;
    }

    #mainGridContainer {
        display: flex;
        flex-direction: column;
    }

    #logoContainer {
        min-height: min(50vw, 300px);
    }

    #column2 {
        display: block !important;
    }
}

@media only screen and (max-width: 1800px) {
    #mainGridContainer {
        grid-template-columns: 1.5fr 1fr;
    }
    #column2 {
        display: none;
    }
}