.linkContainer {
    background-color: white;
    border-radius: 8px;
    
    text-align: center;
    
    cursor: pointer;
    text-decoration: none;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1em;
    min-width: min(max(25%, 300px), 450px);

    color: black;
    font-family: Agrandir;

    font-size: large;
}

.linkContainer:hover {
    text-decoration: underline;
    font-weight: bold;
}