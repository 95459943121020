#main {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: auto;

    font-family: AgrandirHeavy;
    background-color: #f8a348;
}

.topCornerShape {
    margin: 4em;
    position: absolute;
    top: 0;
    right: 0;
    height: 40%;
}

#content {
    position: absolute;

    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
}

#content>* {
    padding: 3em;
}

#textContent {
    text-align: right;
    display: inline;

    grid-column-start: 2;
    grid-column-end: 2;
}

#topLeftContent {
    display: inline;
    grid-row-start: 1;
    grid-row-end: 1;
}
#bottomLeftContent {
    display: inline;
    grid-row-start: 2;
    grid-row-end: 2;
}
#bottomRightContent {
    display: inline;
    grid-row-start: 2;
    grid-row-end: 2;
    
    grid-column-start: 2;
    grid-column-end: 2;
}

h1 {
    font-size: 4em;
    margin-top: 16px;
    margin-bottom: 16px;
}

h2 {
    font-size: 2em;
    margin-top: 16px;
    margin-bottom: 16px;
}

.description {
    font-size: 1.5em;
}

.fullRelative {
    min-height: calc(100vh/3);
}


@media only screen and (max-width: 1300px) {
    #content {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: auto;
    }

    #content>* {
        min-height: 300px;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    #textContent {
        text-align: left;
        min-height: auto;
    }
}