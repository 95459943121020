.tile::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0.45turn, #00c4cdaa, #a648ffaa);
    opacity: 0;
    pointer-events: none;
    z-index: 2;
    transition: opacity 0.3s ease;
}

.tile:hover::before {
    opacity: 1;
}

.tile:hover > .titleText {
    opacity: 1;
}

.tile {
    z-index: 2;
}

.tile > div {
    z-index: 1;
}

.titleText {
    position: absolute;
    font-size: 3em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 5 !important;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: white;
}


@media only screen and (max-width: 1024px) { 
    .tile::before {
        opacity: 1;
    }
    
    .tile > .titleText {
        opacity: 1;
    }
}