.fullRelative {
    position: relative;
    width: 100%;
    height: 100%;

    overflow: hidden;
}

.imageStack {
    width: 100%;
    height: 100%;
    background-position: center;
    position: absolute;
}