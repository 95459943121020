:root {
    --icon-size: 200px;
}

.subContentTitle {
    font-size: 2.5em;
    text-align: center;
}

#sponsorsSection,
#partnersSection {
    margin: auto;

    max-width: 1400px;
    width: 90%;
    box-sizing: border-box;

    text-align: center;
}

.companies,
.partners,
.winners {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: center;
    flex-wrap: wrap;
}

.companyLink {
    display: block;
    max-width: var(--icon-size);
    max-height: var(--icon-size);

    width: var(--icon-size);
    height: var(--icon-size);

    border-radius: var(--icon-size);
    overflow: hidden;
}

.companyIcon {
    width: 100%;
    height: 100%;
}

#winnersSection {
    font-size: 1em;
    display: flex;
    flex-direction: column;
}

.winners {
    align-items: center;
    display: flex;
}

.winner {
    min-height: 100%;
    height: 100%;
}

.winnerTitle {
    font-size: 1.25em;
    font-weight: bolder;
}

.winnerImage {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    
    max-width: 324px;
    max-height: 324px;

    width: 50vw;
    height: auto;

    border-radius: 16px;
    overflow: hidden;

}
.winnerLink {
    text-decoration: none;
    color: inherit;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 768px) { 
    .subContentTitle {
        font-size: min(2.5em, 8vw);
    }
}