:root {
    --active: #ffea5c;
    --passive: #fff;
}

@font-face {
    font-family: 'Agrandir';
    src: local('Agrandir'), url('./fonts/Agrandir-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'AgrandirHeavy';
    src: local('AgrandirHeavy'), url('./fonts/Agrandir-GrandHeavy.otf') format('opentype');
}

body, #root {
    min-height: 100vh;
    /* height: 100vh; */
}

html, body {
    font-family: AgrandirHeavy;
    color: var(--passive);
}